.singlePost .mb-2 {
  float: left;
  margin-right: 1em;
  margin-top: 1em;
}

.singlePost .timestamp {
  float: right;
}

.singlePost:hover {
  cursor: pointer;
}

.singlePost.full:hover {
  cursor: initial;
}

.singlePost .persona {
  margin-bottom: 1em;
}

.singlePost .button {
  cursor: pointer;
}

.singlePostWapper {
  margin-bottom: 2em;
}

.singlePost .post-caption {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
