.adminMailboxWrapper {
    margin-top: 1em;
    margin-bottom: 5em;
}

.postsWrapper a {
    text-decoration: none;
    color: inherit;
}

.postMessage {
    margin-top: 2em;
}

.postsSpinner {
    text-align: center;
}

.aboutme-wapper {
    margin-top: 1em;
}
