.myPosts {
    margin-top: 1em;
}

.myPosts .d-grid {
    margin-top: 1em;
}

.logoutButtonSec {
    text-align: center;
    margin-top: 2em;
}